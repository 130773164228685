
import { CustomDate } from "@/mixins/custom-date";
import { Milestone } from "@/models/milestone.interface";
import { Notification } from "@/models/notification.interface";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Loader from "./Loader.vue";

@Component({ components: { Loader } })
export default class MilestoneList extends mixins(CustomDate) {
  @Prop({ required: true })
  billId!: string;
  scrollHeight = 120;
  @Prop({ default: 3 })
  itemSize!: number;
  loading = false;
  loadingMore = false;
  pagination = {
    page: 1,
    size: this.itemSize,
    totalItems: 0,
    totalPages: 0,
  };
  milestones: Milestone[] = [];
  created() {
    this.loading = true;
    this.loadMilestones().then(() => (this.loading = false));
  }

  async loadMoreMilestones() {
    this.loadingMore = true;
    await this.loadMilestones(this.pagination.page + 1);
    this.loadingMore = false;
  }
  async loadMilestones(page = 1) {
    try {
      const response = await this.$store.dispatch("milestone/getMilestones", {
        ...this.pagination,
        page: page,
        bill_id: this.billId,
      });
      this.pagination.page = page;
      this.pagination.totalItems = response.totalItems;
      this.pagination.totalPages = response.totalPages;
      this.milestones = this.milestones.concat(response.milestones);
    } catch (error) {
      const Error: Notification = {
        message: this.$tc("Milestones.loadError"),
        timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
        top: true,
        bottom: false,
        left: false,
        right: false,
        currentPath: this.$route.fullPath,
        error: true,
      };

      this.$store.dispatch("notifications/showNotification", Error);
    }
  }
}
